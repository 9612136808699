import React, {useContext, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Context} from '../../AppContext';
import {Spin, Form} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {Input, Select, SelectOption} from '../../Widgets';

export default function AddressField(props) {
  const {zip_code = null, city = null, disabled = false, prefix = null} = props;
  const form = Form.useFormInstance();
  const app = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedCity, setSelectedCity] = useState(city);

  const getZipCode = useCallback(
    async (zip_code) => {
      setLoading(true);
      try {
        let resp = await app.actions.getZipCode(zip_code);
        if (resp) {
          form.setFieldsValue({
            zip_code,
            [prefix ? `${prefix}city` : 'city']: resp.countyName,
            [prefix ? `${prefix}district` : 'district']: resp.townName,
          });
        }
      } catch (err) {
        console.warn(err);
      }
      setLoading(false);
    },
    [app.actions, form, prefix],
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        let resp = await app.actions.getCities();
        setCities(resp);
      } catch (err) {
        console.warn(err);
      }
      setLoading(false);
    })();
  }, [app.actions]);

  const getDistricts = useCallback(async () => {
    if (selectedCity) {
      setLoading(true);
      try {
        let resp = await app.actions.getDistricts(selectedCity);
        setDistricts(resp);
      } catch (err) {
        console.warn(err);
      }
      setLoading(false);
    }
  }, [app.actions, selectedCity]);

  useEffect(() => {
    getDistricts();
  }, [getDistricts]);

  return (
    <Wrapper>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{fontSize: 16}} />}
        style={{
          alignSelf: 'center',
          marginRight: 10,
          marginBottom: 10,
          flexBasis: 50,
        }}
      />
      <StyledFormItem name="zip_code">
        <Input
          placeholder="郵遞區號"
          disabled={loading || disabled}
          onBlur={() => {
            if (/[0-9]{3}/g.test(zip_code)) {
              getZipCode(zip_code);
            }
          }}
          type="short"
          style={{width: '100%'}}
        />
      </StyledFormItem>
      <StyledFormItem name={prefix ? `${prefix}city` : 'city'}>
        <Select
          placeholder="縣市"
          onChange={(value) => {
            setSelectedCity(value);
            form.setFieldsValue({
              [prefix ? `${prefix}city` : 'city']: value,
              [prefix ? `${prefix}district` : 'district']: null,
              zip_code: null,
            });
          }}
          disabled={loading || disabled}>
          <SelectOption value="" key="empty" disabled>
            縣市
          </SelectOption>
          {cities.map((c) => (
            <SelectOption key={c.countyName} value={c.countyName}>
              {c.countyName}
            </SelectOption>
          ))}
        </Select>
      </StyledFormItem>
      <StyledFormItem name={prefix ? `${prefix}district` : 'district'}>
        <Select
          placeholder="鄉鎮市區"
          onChange={(value) => {
            // set zip_code
            let instance = districts.find((t) => t.townName === value);

            if (instance) {
              form.setFieldsValue({
                zip_code: instance.zipCode,
              });
            }
          }}
          disabled={loading || disabled}>
          <SelectOption value="" key="empty" disabled>
            鄉鎮市區
          </SelectOption>
          {districts.map((t) => (
            <SelectOption key={t.townName} value={t.townName}>
              {t.townName}
            </SelectOption>
          ))}
        </Select>
      </StyledFormItem>
      <StyledFormItem
        name={prefix ? `${prefix}address` : 'address'}
        style={{flex: '0 0 100%', maxWidth: '100%'}}>
        <Input
          placeholder="詳細地址路段"
          className="address"
          disabled={loading || disabled}
          type="long"
          style={{margin: 0}}
        />
      </StyledFormItem>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledFormItem = styled(Form.Item)`
  flex: 0 0 calc(33.33333% - 9px);
  max-width: calc(33.33333% - 9px);
  input {
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 10px 12px;
    font-size: 14px;
    color: #505050;
    margin: 0;
  }
  .ant-select.ant-select-in-form-item {
    margin: 0;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    display: inline-flex;
    align-items: center;
  }
`;
