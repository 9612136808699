import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {Divider} from '../../Widgets';
import {Switch} from 'antd';
import useCalculations from '../../hooks/use-calculation';
import {Context} from '../../AppContext';
import CouponBox from './CouponBox';

export default function PaymentDetails({
  calculations,
  config,
  setConfig,
  params,
  setParams,
}) {
  const app = useContext(Context);
  const {profile} = app.state;
  const [resetCouponTrigger, setResetCouponTrigger] = useState(0);

  let {
    items_amount, //商品金額（未稅）
    items_tax, //商品金額（稅）
    discount, //活動折抵（未稅）
    discount_tax, //活動折抵（稅）
    fee, //運費(未稅)
    fee_tax, //運費（稅）
    bonus, //紅利（未稅）
    bonus_tax, //紅利（稅）
    feedback_bonus, //此訂單將會送的紅利
    tax,
    amount, //訂單總計金額(含稅)
  } = useCalculations(calculations);

  const maxBonusUsage = Math.min(
    Math.floor((items_amount + items_tax) * 0.3),
    profile.bonus,
  );

  const handleSwitchChange = (value) => {
    setConfig('extra', {use_bonus: value});
    setResetCouponTrigger((prev) => prev + 1);
  };

  return (
    <div>
      <div>
        <PriceItem>
          <h4>商品總額（含稅）</h4>
          <p>$ {items_amount + items_tax}</p>
        </PriceItem>
        {bonus !== 0 && (
          <PriceItem>
            <h4>紅利折抵（含稅）</h4>
            <p style={{color: '#ff5353'}}>$ {bonus + bonus_tax}</p>
          </PriceItem>
        )}
        {discount !== 0 && (
          <PriceItem>
            <h4>活動折抵（含稅）</h4>
            <p style={{color: '#ff5353'}}>$ {discount + discount_tax}</p>
          </PriceItem>
        )}
        <PriceItem>
          <h4>運費（含稅）</h4>
          <p>$ {fee + fee_tax}</p>
        </PriceItem>
      </div>
      <Divider paddingSize="lg" />
      <TotalPriceBox>
        <div>
          <h3>訂單總金額(含稅)</h3>
          <TotalPrice>$ {amount}</TotalPrice>
        </div>
        <div>
          <span>訂單總計金額(含稅)</span>
        </div>
      </TotalPriceBox>
      <Card style={{marginBottom: '8px'}}>
        <h3>折扣代碼</h3>
        <CouponBox setParams={setParams} resetTrigger={resetCouponTrigger} />
      </Card>
      <Card>
        <h3 style={{marginBottom: '12px'}}>紅利折抵</h3>
        <StyledP style={{marginBottom: '10px'}} medium>
          剩餘紅利：{profile.bonus} 點 (最高可折抵訂單金額30%)
        </StyledP>
        <SwitchBox>
          <Switch
            defaultChecked={config.use_bonus}
            onChange={handleSwitchChange}
            disabled={
              /*
                訂單滿 100 元即可使用紅利，只能折訂單30％
              */
              !(items_amount + items_tax > 100 && profile.bonus > 0)
            }
          />
          <p>
            使用紅利金折抵 <span>{maxBonusUsage}</span> 點
          </p>
        </SwitchBox>
      </Card>
    </div>
  );
}

const Card = styled.div`
  padding: 12px 20px;
  background-color: #fffae0;
  border-radius: 10px;
`;

const StyledP = styled.p`
  font-size: ${(props) => (props.medium ? '14px' : '12px')};
  color: #505050;
`;

const PriceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 12px;
  h4 {
    color: #9b9b9b;
    margin-bottom: 0;
  }
  p {
    color: #505050;
    text-align: right;
    margin-bottom: 0;
    font-weight: 500;
  }
`;

const TotalPriceBox = styled.div`
  margin-bottom: 14px;
  > div {
    &:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-size: 16px;
        font-weight: 500;
        color: #4a4a4a;
        margin-bottom: 0;
      }
    }
    &:nth-child(2) {
      font-size: 12px;
      color: #4a4a4a;
      text-align: right;
    }
  }
`;

const TotalPrice = styled.span`
  font-size: 28px;
  font-weight: bold;
  color: #ff5353;
`;

const SwitchBox = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  p {
    font-size: 16px;
    font-weight: 500;
    color: #505050;
    margin: 0 0 0 14px;
  }
  span {
    display: inline-block;
    color: #ff5353;
  }
`;
